<template>
    <baseContainer class="myTeLive">
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad">
                <div class="van_van_radio">
                    <van-radio-group v-model="listQuery.isMain" direction="horizontal"  @change="onSearch">
                        <van-radio v-for="(item, key) in typeDiffer" :name="key" :key="key">{{item}}</van-radio>
                    </van-radio-group>
                </div>
                <div class="van_tabs_layout">
                    <van-tabs v-model="listQuery.dateType" @change="onSearch">
                        <van-tab v-for="(item, key) in liveTimePeriod" :title="item" :name="key" :key="key"/>
                    </van-tabs>
                </div>
                <div class="myStLive_content">
                    <BarList :list="list" @callMore="(item)=>{callMore(item, '/liveDetails')}"/>
                </div>
            </van-list>
        </van-pull-refresh>
    </baseContainer>
</template>

<script>
import HeadNav from '../../components/headNav';
import BarList from './../home/components/BarList';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import constant from './../../utils/constant';
import api from './../../api/teacher';

export default {
    name: "myTeLive",
    components: {
        HeadNav,
        BarList
    },
    mixins: [DropDownRefresh],
    data () {
        return {
            liveTimePeriod: constant.liveTimePeriod,
            typeDiffer: constant.typeDiffer,
            listQuery: {
                type: constant.liveTypeKey.all,
                dateType: constant.liveTimePeriodKey.month,
                isMain: constant.typeDifferKey.our,
                isTeacher: true,
                name: '',
                current: 1,
                size: 10,
                noCorp: true
            }
        }
    },
    methods: {
        callMore (item, url) {
            this.$utils.callMores(item, url, constant.checkLive.noCheck);
        },
        // 查询首页直播列表
        getList () {
            api.queryTeacherLiveList(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    }
}
</script>

<style scoped lang="less">
    .myTeLive {
        /deep/.contents{
            background: #F5F5F5;
        }
        .van_van_radio{
            padding: 20px 40px 32px;
            background: #fff;
        }
        .van_tabs_layout, .myStLive_content{
            padding: 0 40px;
        }
    }
</style>